import React from 'react';

export const Lightbulb = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="110"
			height="117"
			viewBox="0 0 193 205"
		>
			<defs>
				<filter id="filter_1" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="4"></feOffset>
					<feGaussianBlur></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0.2588235 0 0 0 0 0.7294118 0 0 0 0 0.8078431 0 0 0 1 0"></feColorMatrix>
					<feBlend in2="BackgroundImageFix" result="effect0_dropShadow"></feBlend>
					<feBlend in="SourceGraphic" in2="effect0_dropShadow" result="shape"></feBlend>
				</filter>
				<path id="artboard_1" d="M0 0H193V205H0z"></path>
				<clipPath id="clip_1">
					<use clipRule="evenodd" xlinkHref="#artboard_1"></use>
				</clipPath>
			</defs>
			<g clipPath="url(#clip_1)">
				<use fill="#FFF" xlinkHref="#artboard_1"></use>
				<g transform="translate(0 7)">
					<path
						fill="#42BACE"
						fillOpacity="0.14"
						stroke="none"
						d="M55 110c30.376 0 55-24.624 55-55S85.376 0 55 0 0 24.624 0 55s24.624 55 55 55z"
						transform="translate(0 27)"
					></path>
					<path
						fill="#42BACE"
						fillOpacity="0.14"
						stroke="none"
						d="M69.5 139c38.384 0 69.5-31.116 69.5-69.5S107.884 0 69.5 0 0 31.116 0 69.5 31.116 139 69.5 139z"
						transform="translate(44 40)"
					></path>
					<path
						fill="#42BACE"
						fillOpacity="0.14"
						stroke="none"
						d="M39.5 79C61.315 79 79 61.315 79 39.5S61.315 0 39.5 0 0 17.685 0 39.5 17.685 79 39.5 79z"
						transform="translate(114 6)"
					></path>
					<g filter="url(#filter_1)">
						<path
							fill="#FFF"
							stroke="none"
							d="M69 58c38.108 0 69-12.984 69-29S107.108 0 69 0C30.892 0 0 12.984 0 29s30.892 29 69 29z"
							transform="translate(44 134)"
						></path>
					</g>
					<path
						fill="#E0E5F8"
						fillOpacity="0.363"
						stroke="none"
						d="M36.5 24C56.658 24 73 18.627 73 12S56.658 0 36.5 0 0 5.373 0 12s16.342 12 36.5 12z"
						transform="translate(76 155)"
					></path>
					<g transform="translate(66)">
						<path
							fill="#D73A4A"
							stroke="none"
							d="M0 0v2.904c0 1.731 1.295 3.463 3.886 4.784 5.182 2.641 13.583 2.641 18.764 0 2.591-1.321 3.887-3.053 3.887-4.784V0H0z"
							transform="translate(33.547 161.524)"
						></path>
						<path
							fill="#D73A4A"
							stroke="none"
							d="M0 0v4.664c0 2.78 1.837 5.56 5.51 7.68 7.348 4.243 19.26 4.243 26.608 0 3.674-2.12 5.511-4.9 5.511-7.68V0H0z"
							transform="translate(28.001 150.65)"
						></path>
						<path
							fill="#EAB6BC"
							stroke="none"
							d="M0 0v4.664c0 2.78 1.837 5.56 5.51 7.681 7.348 4.242 19.26 4.242 26.608 0 3.674-2.121 5.511-4.901 5.511-7.68V0H0z"
							transform="translate(28.001 147.494)"
						></path>
						<path
							fill="#D73A4A"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 141.072)"
						></path>
						<path
							fill="#EAB6BC"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 136.259)"
						></path>
						<path
							fill="#D73A4A"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 131.525)"
						></path>
						<path
							fill="#EAB6BC"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 126.317)"
						></path>
						<path
							fill="#D73A4A"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 121.583)"
						></path>
						<path
							fill="#EAB6BC"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 116.77)"
						></path>
						<path
							fill="#D73A4A"
							stroke="none"
							d="M0 0v4.734c0 3.397 2.244 6.793 6.733 9.384 8.977 5.183 23.53 5.183 32.508 0 4.488-2.591 6.732-5.987 6.732-9.384V0H0z"
							transform="translate(23.83 112.036)"
						></path>
						<path
							fill="#D73A4A"
							stroke="none"
							d="M39.24 3.887c8.977 5.183 8.977 13.586 0 18.768-8.976 5.183-23.53 5.183-32.507 0-8.977-5.182-8.977-13.585 0-18.768s23.53-5.183 32.508 0"
							transform="translate(23.83 98.764)"
						></path>
						<path
							fill="#FFF"
							stroke="#FFF"
							strokeWidth="2"
							d="M79.184 11.958c-4.263-3.862-9.29-6.863-14.942-8.92C58.777 1.048 52.982.026 47.017 0c-5.965-.025-11.77.947-17.251 2.89-5.67 2.01-10.722 4.969-15.017 8.794C5.163 20.22.063 32.475.001 47.123-.092 68.83 10.4 78.625 18.06 85.776c1.163 1.087 2.263 2.113 3.24 3.104 6.253 6.352 7.143 17.907 7.25 22.265.257 2.483 2.005 5.076 5.245 6.973 7.04 4.122 18.455 4.122 25.496 0 3.24-1.897 4.987-4.337 5.245-6.82.143-4.357 1.132-15.904 7.439-22.203.985-.982 2.093-2 3.266-3.076 7.721-7.085 18.296-16.79 18.389-38.498.062-14.648-4.934-26.945-14.447-35.563"
						></path>
						<path
							fill="#EAB6BC"
							stroke="none"
							d="M26.467 2.624c-5.975-3.499-15.663-3.499-21.638 0-6.439 3.77-6.439 7.796 0 11.567 5.975 3.498 15.663 3.498 21.638 0 6.44-3.771 6.44-7.796 0-11.567"
							transform="translate(31.168 102.426)"
						></path>
						<path
							fill="#CDEDF2"
							fillOpacity="0.798"
							stroke="none"
							d="M12.802 79.305a1.44 1.44 0 01-1.411-1.162L.027 20.668a1.441 1.441 0 012.134-1.526l10.12 5.85 6.664-14.584a1.441 1.441 0 012.23-.508l7.126 5.925L36.87.73a1.441 1.441 0 012.675.941L28.048 72.614a1.44 1.44 0 11-2.843-.46L35.417 9.12l-5.461 9.623a1.442 1.442 0 01-2.174.397l-7.001-5.822-6.54 14.313a1.44 1.44 0 01-2.03.648l-8.743-5.054 10.747 54.359a1.44 1.44 0 01-1.414 1.72"
							transform="translate(26.56 34.958)"
						></path>
						<path
							fill="#CDEDF2"
							fillOpacity="0.187"
							stroke="none"
							d="M11.719 6.647C1.728 14.197-2.74 25.124 1.74 31.053c4.48 5.93 16.212 4.615 26.204-2.935 9.99-7.55 14.458-18.477 9.978-24.406-4.48-5.929-16.212-4.615-26.203 2.935"
							transform="translate(8.17 8.517)"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
};
