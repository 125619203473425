import React, { Component } from 'react';
import styles from './styles.module.css';
import { PadlockSVG } from 'src/components/SVGs/PadlockSVG';

interface Props {
	onOpenShareSettingsClick(): void;
	onSkipClick(): void;
}

const ProfileVisibilityBanner = (props: Props) => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.topTile}>
					<div className={styles.heading}>
						Your profile is looking great! Check out the preview below.
					</div>
					<div className={styles.contents}>
						{
							"When you're ready to share it with potential employers, \n switch on sharing.  We won't share it with anyone without your permission first."
						}
					</div>
				</div>
				<div className={styles.bottomTile}>
					<div
						className={styles.OpenProfileButton}
						onClick={() => props.onOpenShareSettingsClick()}
					>
						<div className={styles.image}>{<PadlockSVG width={30} height={30} />}</div>
						<div className={styles.OpenProfileText}>Open my Profile Share Settings</div>
					</div>
					<div>
						<div className={styles.SkipForNowText} onClick={() => props.onSkipClick()}>
							SKIP FOR NOW
						</div>
					</div>
				</div>
				<div />
			</div>
		</>
	);
};

export { ProfileVisibilityBanner };
