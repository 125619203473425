import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { Badge } from './SVGs/badge';

interface BadgeTileData {
	name: string;
}

const BadgeTile = (props: BadgeTileData) => {
	return (
		<div className={classNames(styles.container)}>
			{<Badge />}
			{<div className={classNames(styles.title)}>{props.name}</div>}
		</div>
	);
};
export { BadgeTile };
