import React from 'react';

export const Badge = () => {
	return (
	<svg width="110" height="117" viewBox="0 0 210 210">
		<g transform="translate(0 -87)">
			<circle
				cx={105}
				cy={192}
				r={92.099}
				fill="#54cadc"
				stroke="#5887d1"
				strokeWidth={5.802}
			/>
			<circle
				r={80.77}
				cy={192}
				cx={105}
				fill="#54cadc"
				stroke="#5887d1"
				strokeWidth={5.802}
			/>
			<rect
				width={26.059}
				height={91.489}
				x={-74.548}
				y={164.266}
				ry={8.553}
				rx={8.553}
				transform="rotate(-45)"
				fill="#54cadc"
				stroke="#f3fdff"
				strokeWidth={5.802}
			/>
			<rect
				rx={8.553}
				ry={8.553}
				y={15.774}
				x={196.981}
				height={91.489}
				width={26.059}
				transform="rotate(45)"
				fill="#54cadc"
				stroke="#f3fdff"
				strokeWidth={5.802}
			/>
			<path
				d="M138.211 159.052c-4.464-4.495-12.445-4.01-16.578.695l-17.587 16.92c6.565 5.883 15.783 9.97 24.677 7.897 3.565-3.887 12.52-10.218 12.972-16.007.401-3.49-.918-7.102-3.484-9.505zM72.19 224.947c4.464 4.496 12.445 4.01 16.577-.694l17.187-16.92c-6.565-5.883-15.783-9.97-24.677-7.897-3.565 3.887-12.12 10.218-12.571 16.007-.402 3.49.918 7.102 3.484 9.504z"
				fill="#f3fdff"
			/>
    	</g>
    </svg>
	);
};
