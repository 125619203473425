import React from 'react';

interface Props {
  width: number;
  height: number;
}

const PadlockSVG = (props: Props) => (
    <svg
    width={`${props.width}px`}
    height={`${props.height}px`}
    viewBox="0 0 60 60"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Lunacy</desc>
    <defs>
      <path
       d="M 217.10183,0.97549948 C 168.28486,0.28823948 120.57651,24.880319 92.993776,64.102299 60.614886,111.20256 48.896586,169.21921 48.506176,225.20251 c 2.32803,8.72136 -2.42951,10.50055 -10.63772,9.11685 -11.59706,1.23278 -28.6660902,-4.38172 -34.9252402,8.39036 -3.15761999,19.52226 -0.55586,39.76269 -1.40471,59.57283 0.25631,67.59659 -0.51244,135.32668 0.38378,202.83994 1.38673,13.68287 17.7525202,12.83564 28.2610002,12.29247 133.208474,0 266.416964,0 399.625434,0 12.52079,-0.43592 15.52095,-13.71625 14.00647,-23.67292 -0.25694,-82.36086 0.512,-164.8552 -0.38385,-247.13282 -1.3867,-13.68285 -17.75248,-12.83563 -28.26097,-12.29246 -10.48217,0 -20.96439,0 -31.44659,0 1.40986,-39.41521 2.51272,-79.69402 -9.6646,-117.80749 C 362.37461,73.605029 335.30991,32.041279 292.32034,13.857049 268.83049,2.9182495 242.8244,0.22522948 217.10183,0.97549948 Z M 231.48279,76.629439 c 19.44309,-1.2763 39.82238,1.9198 55.43205,14.08091 27.56834,20.037661 37.2473,55.017731 41.03244,86.834481 2.02176,18.38514 1.18458,36.94537 -1.03255,55.27335 -68.67452,-0.38696 -137.34876,-0.82148 -206.02257,-1.31436 -5.83949,-39.34004 -3.96658,-83.2439 19.95899,-116.99156 19.20577,-25.422561 52.33408,-39.286301 84.39553,-37.848551 2.07846,-0.0292 4.15744,-0.0199 6.23609,-0.0342 z m -0.44027,226.509831 c 13.1656,0.018 30.28347,1.39548 37.42773,13.91686 3.16079,17.62476 -10.57745,31.49741 -20.85672,44.07241 -5.80991,14.42783 -1.86041,30.65139 -3.59595,45.55967 -0.25401,9.13909 -0.59275,18.27562 -0.91842,27.4125 -14.12843,-0.26378 -28.25599,-0.57061 -42.38244,-0.92083 0.0864,-22.86556 2.20032,-45.94567 1.00129,-68.67617 -4.6218,-13.9925 -19.96647,-24.00932 -20.18838,-39.90724 -1.30385,-15.59485 18.18015,-20.64931 30.79921,-20.92239 6.22446,-0.46323 12.4726,-0.60588 18.71368,-0.53483 z"
        id="path_1"
      />
      <clipPath id="mask_1">
        <use xlinkHref="#path_1" />
      </clipPath>
    </defs>
    <g id="bg/logic">
      <g id="Group-12">
        <g id="Group-10">
          <path
           d="M 21.793047,0.86999017 C 17.163197,0.80241383 12.63849,3.2204843 10.022516,7.0770715 6.9516693,11.708308 5.8402939,17.412923 5.803267,22.917604 c 0.2207927,0.857547 -0.2304171,1.032489 -1.0088921,0.896435 -1.0998768,0.121216 -2.7187205,-0.430843 -3.3123446,0.824998 -0.2994718,1.919571 -0.052718,3.909754 -0.1332241,5.857632 0.024309,6.646583 -0.0486,13.306293 0.036398,19.94468 0.1315188,1.345402 1.6836667,1.262094 2.6803014,1.208687 12.6336254,0 25.2672514,0 37.9008754,0 1.187482,-0.04286 1.472023,-1.348684 1.328387,-2.327693 -0.02437,-8.098312 0.04856,-16.20975 -0.03641,-24.299877 -0.131513,-1.345397 -1.683664,-1.262092 -2.6803,-1.208683 -0.994135,0 -1.988283,0 -2.982424,0 0.133711,-3.875587 0.23831,-7.83609 -0.916602,-11.583681 C 35.57086,8.0114482 33.004021,3.9246013 28.926845,2.1365968 26.699045,1.0610154 24.2326,0.79621822 21.793047,0.86999017 Z m 1.363905,7.43884003 c 1.844002,-0.125495 3.776791,0.1887684 5.257229,1.3845364 2.61461,1.9702464 3.532572,5.4097394 3.891559,8.5381914 0.191748,1.807759 0.112346,3.632735 -0.09793,5.434874 -6.513159,-0.03805 -13.026292,-0.08077 -19.539386,-0.129238 -0.553822,-3.868196 -0.376194,-8.18514 1.892931,-11.503453 1.821495,-2.4997284 4.963417,-3.8629113 8.004156,-3.7215411 0.197124,-0.00287 0.394296,-0.00197 0.591438,-0.00335 z m -0.04176,22.2720778 c 1.248639,0.0016 2.872116,0.137212 3.549682,1.368406 0.299773,1.732992 -1.003176,3.097052 -1.978071,4.333517 -0.551017,1.418647 -0.176442,3.013865 -0.341043,4.479753 -0.02409,0.898621 -0.05622,1.796989 -0.0871,2.695396 -1.339955,-0.02593 -2.679828,-0.0561 -4.019594,-0.09054 0.0082,-2.248307 0.20868,-4.51771 0.09496,-6.752735 -0.438336,-1.375844 -1.89364,-2.360769 -1.914686,-3.923967 -0.123658,-1.533397 1.724223,-2.030389 2.921028,-2.057239 0.590333,-0.04555 1.182914,-0.05958 1.774824,-0.05258 z"
            id="path_1"
            fill="#42c7db"
            stroke="none"
          />
        </g>
        </g>
    </g>
  </svg>
);

export { PadlockSVG };
