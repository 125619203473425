import React from 'react';
import styles from './styles.module.css';
import { Link, navigate } from 'gatsby';

import { Layout } from 'src/components/Layout';

import { Interest } from 'src/model/Interest';
import { Spinner } from 'src/components/Spinner';
import { ButtonList } from 'src/components/ButtonList';
import { SkillTile } from 'src/components/SkillTile';
import { BadgeTile } from 'src/components/BadgeTile';
import { ProfileVisibilityBanner } from 'src/components/ProfileVisibilityWarningBanner';
import { KeystoneUser, UserManager } from 'src/helpers/UserManager';
import { InterestManager } from 'src/helpers/InterestManager';
import { Button } from 'antd';
import { SaveOutlined, MailOutlined } from '@ant-design/icons';
import { NotificationOperations } from 'src/model/Notifications';
import { ExternalSkillManager } from 'src/helpers/ExternalSkillManager/ExternalSkillManager';
import { WorkSampleManager } from 'src/helpers/WorkSampleManager/WorkSampleManager';
import { SettingsManager } from 'src/helpers/SettingsManager';
import { DeliveryManager } from 'src/helpers/DeliveryManager';

interface Props {}

interface DisplayInterest extends Interest {
	selected: boolean;
}

interface State {
	loading: boolean;
	authUser: KeystoneUser | undefined;
	firstName?: string;
	lastName?: string;
	email?: string;
	skills?: any;
	externalSkills?: any;
	workSamples?: any;
	badges?: any;
	interests?: Interest[];
	displayedInterests?: DisplayInterest[];
	interestsModified: boolean;
	codingPopupShown: boolean;
	profileShowViewDetails?: boolean;
	profileShowSettings?: boolean;
	profileShowAddSkills?: boolean;
	profileShowWorkSamples?: boolean;
	profileVisibilityBannerOpen?: boolean;
	profileVisibilityStaff?: boolean;
	profileVisibilityEmployers?: boolean;
	deliveriesForUser?: any;
	profileEmailed: boolean;
}

export default class Profile extends React.Component<Props, State> {
	state: State = {
		loading: true,
		authUser: undefined,
		firstName: '',
		lastName: '',
		email: '',
		skills: [
			{ id: '', description: '' },
			{ id: '', description: '' },
		],
		badges: [
			{ id: '', description: '' },
			{ id: '', description: '' },
		],
		interests: [],
		displayedInterests: [],
		interestsModified: false,
		codingPopupShown: false,
		profileVisibilityBannerOpen: false,
		profileVisibilityStaff: false,
		profileVisibilityEmployers: false,
		profileEmailed: false,
	};

	async componentDidMount() {
		const userDetails = await UserManager.getAuthenticatedUser();
		if (userDetails && userDetails.id && !userDetails.guest) {
			const allInterests = await InterestManager.getInterests();
			const displayedInterests =
				allInterests &&
				allInterests?.map((interest: Interest) => {
					return {
						...interest,
						selected: userDetails.interests
							? userDetails.interests.some((obj) => obj.id === interest.id)
							: false,
					};
				});
			const externalSkills = await ExternalSkillManager.getAllExternalSkillsForUser(userDetails.id);
			const workSamples = await WorkSampleManager.getAllWorkSamplesForUser(userDetails.id);
			this.setState({
				loading: false,
				authUser: userDetails,
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				email: userDetails.email,
				skills: userDetails.skills,
				externalSkills: externalSkills,
				workSamples: workSamples,
				badges: userDetails.badges,
				interests: userDetails.interests,
				displayedInterests: displayedInterests,
				profileVisibilityBannerOpen: true,
				profileShowViewDetails:
					(
						await SettingsManager.getSetting('ProfileShowViewDetails', userDetails.id)
					)?.toLocaleLowerCase() == 'true',
				profileShowSettings:
					(
						await SettingsManager.getSetting('ProfileShowSettings', userDetails.id)
					)?.toLocaleLowerCase() == 'true',
				profileShowAddSkills:
					(
						await SettingsManager.getSetting('ProfileShowAddSkills', userDetails.id)
					)?.toLocaleLowerCase() == 'true',
				profileShowWorkSamples:
					(
						await SettingsManager.getSetting('ProfileShowWorkSamples', userDetails.id)
					)?.toLocaleLowerCase() == 'true',
				deliveriesForUser:
					(
						await SettingsManager.getSetting('ProfileShowEmailProfile', userDetails.id)
					)?.toLocaleLowerCase() == 'true'
						? await DeliveryManager.getDeliveriesForUser(userDetails.id)
						: false,
			});
		} else {
			// no user found so send them to sign in
			navigate('/sign-in?url=profile');
		}
	}

	componentDidUpdate() {
		const { authUser, codingPopupShown } = this.state;
		if (authUser && authUser.interests && !codingPopupShown) {
			// TODO: add a setting or some other controller for when this should appear rather than using hard coded match
			if (authUser.interests.filter((interest) => interest.name == 'Coding').length > 0) {
				PubSub.publish(
					NotificationOperations[NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE],
					{ type: NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE, text: undefined }
				);
				this.setState({ codingPopupShown: true });
			}
		}
	}

	render() {
		const {
			authUser,
			firstName,
			lastName,
			email,
			skills,
			externalSkills,
			workSamples,
			loading,
			displayedInterests,
			badges,
			interestsModified,
			profileShowViewDetails,
			profileShowSettings,
			profileShowAddSkills,
			profileShowWorkSamples,
			deliveriesForUser,
			profileEmailed,
		} = this.state;
		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<div className={styles.profContainer}>
							{this.state.profileVisibilityBannerOpen &&
							this.state.profileVisibilityStaff &&
							!this.state.profileVisibilityEmployers ? (
								<div className={styles.banner}>
									<ProfileVisibilityBanner
										onOpenShareSettingsClick={() => {
											navigate('/profile-settings');
										}}
										onSkipClick={() => {
											this.setState({ profileVisibilityBannerOpen: false });
										}}
									/>
								</div>
							) : null}

							<div className={styles.headingContainer}>
								<h1 className={styles.overflowEllipsis}>{`${firstName}'s Profile`}</h1>
								{profileShowSettings ? (
									<Link to={'/profile-settings'} className={styles.activeElements}>
										{'SETTINGS >'}
									</Link>
								) : null}
							</div>
							<div className={styles.lineBreak}></div>
							<div className={styles.headingLeft}>
								<h2>Details</h2>
							</div>
							<div className={styles.detailsContainer}>
								<div className={styles.detailsLeftBlock}>
									<div className={styles.fullName}>
										<div className={styles.labelText}>Full Name:</div>
										<div className={styles.pText}>{`${firstName} ${lastName}`}</div>
									</div>
									<div className={styles.email}>
										<div className={styles.labelText}>Email Address:</div>
										<div className={styles.pText}>{`${email}`}</div>
									</div>
								</div>
								<div className={styles.containerVertical}>
									{profileShowViewDetails ? (
										<Link to={'/profile-details'} className={styles.activeElements}>
											{'VIEW DETAILS >'}
										</Link>
									) : null}
									{authUser && deliveriesForUser ? (
										<Button
											icon={<MailOutlined />}
											onClick={async () => {
												authUser.emailingProfile = true;
												const result = await UserManager.updateUser(authUser);
												this.setState({ profileEmailed: true });
											}}
											disabled={profileEmailed}
										>
											{profileEmailed ? 'Profile emailed' : 'Email Profile PDF'}
										</Button>
									) : null}
								</div>
							</div>
							<div className={styles.skillsContainer}>
								<div className={styles.skillLeftBlock}>
									<h2>Badges</h2>
								</div>
							</div>
							<div className={styles.skillsList}>
								{badges &&
									badges.map((badge: any, i: number) => {
										return <BadgeTile name={badge.description} key={i} />;
									})}
							</div>
							<div className={styles.lineBreak} />
							<div className={styles.skillsContainer}>
								<div className={styles.skillLeftBlock}>
									<h2>Skills</h2>
								</div>
								{profileShowAddSkills ? (
									<Link to={'/add-skill'} className={styles.activeElements}>
										{'ADD SKILLS >'}
									</Link>
								) : null}
							</div>
							<div className={styles.skillsList}>
								{skills &&
									skills.map((skill: any, i: number) => {
										return <SkillTile name={skill.description} key={i} />;
									})}
								{externalSkills &&
									externalSkills.allExternalSkills.map((skill: any, i: number) => {
										return (
											<SkillTile
												name={skill.proficiencyType}
												key={i}
												allowHover={true}
												tooltip={skill.info}
												onIconClick={() => {
													navigate(`/add-skill/?existing=${skill.id}`);
												}}
												onEditClick={() => {
													navigate(`/add-skill/?existing=${skill.id}`);
												}}
												onDeleteClick={async () => {
													ExternalSkillManager.deleteExternalSkill({ id: skill.id });
													externalSkills.allExternalSkills = externalSkills.allExternalSkills.filter(
														(externalSkill: any) => {
															return externalSkill.id != skill.id;
														}
													);
													this.setState({
														externalSkills,
													});
												}}
											/>
										);
									})}
							</div>
							<div className={styles.lineBreak} />
							{profileShowWorkSamples ? (
								<div className={styles.skillsContainer}>
									<div className={styles.skillLeftBlock}>
										<h2>Work Samples</h2>
									</div>
									<Link to={'/add-work-sample/'} className={styles.activeElements}>
										{'ADD WORK SAMPLE >'}
									</Link>
								</div>
							) : null}
							{profileShowWorkSamples ? (
								<div className={styles.skillsList}>
									{workSamples &&
										workSamples.allWorkSamples.map((sample: any, i: number) => {
											return (
												<SkillTile
													name={sample.title}
													key={i}
													allowHover={true}
													url={sample.url}
													tooltip={sample.description}
													onIconClick={() => {
														navigate(`/add-work-sample/?existing=${sample.id}`);
													}}
													onEditClick={() => {
														navigate(`/add-work-sample/?existing=${sample.id}`);
													}}
													onDeleteClick={async () => {
														WorkSampleManager.deleteWorkSample({ id: sample.id });
														workSamples.allWorkSamples = workSamples.allWorkSamples.filter(
															(item: any) => {
																return item.id != sample.id;
															}
														);
														this.setState({
															workSamples,
														});
													}}
												/>
											);
										})}
								</div>
							) : null}
							<div className={styles.lineBreak}></div>
							<div className={styles.headingLeft}>
								<h2>Interests</h2>
							</div>
							<span className={styles.interestsList}>
								{displayedInterests && (
									<ButtonList
										buttons={displayedInterests
											.sort((a, b) => (a.description || '').localeCompare(b.description || ''))
											.map((interest: DisplayInterest) => {
												return {
													id: interest.id || '',
													title: interest.description || '',
													selected: interest.selected,
												};
											})}
										onStateChange={(id: string) => {
											const interest = displayedInterests.find((interest) => interest.id === id);
											if (interest) interest.selected = !interest.selected;
											this.setState({
												displayedInterests: [...displayedInterests],
												interestsModified: true,
											});
										}}
									/>
								)}
								<div className={styles.saveInterestsButtonContainer}>
									<Button
										disabled={!interestsModified}
										ghost={!interestsModified}
										onClick={async () => {
											// Create guest user and save interests on backend
											const updatedUser = displayedInterests
												? await UserManager.setInterestsForAuthenticatedUser(
														displayedInterests.filter((interest) => interest.selected)
												  )
												: await UserManager.setInterestsForAuthenticatedUser([]);
											if (!updatedUser) {
												console.error(`Failed to update interests for user`);
											} else {
												// Reload to remove any pop-ups that are no longer relevant
												// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
												if (typeof window !== 'undefined') window.location.reload();
											}
										}}
										icon={<SaveOutlined />}
									>
										Save Interests
									</Button>
								</div>
							</span>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
