import React, { useState } from 'react';
import styles from './styles.module.css';
import { Lightbulb } from './SVGs/lightbulb';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { Tooltip } from 'src/components/Tooltip';

interface SkillTileData {
	name: string;
	url?: string;
	allowHover?: boolean;
	tooltip?: string;
	onIconClick?: () => void;
	onEditClick?: () => void;
	onDeleteClick?: () => void;
}

const SkillTile = (props: SkillTileData) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<Tooltip caption={props.tooltip} mouseEnterDelay={0.25}>
			<div className={styles.container}>
				<div className={styles.hover}>
					<div onClick={props.onIconClick}>
						<Lightbulb />
					</div>
					{props.url ? (
						<div className={styles.title}>
							<a className={styles.url} href={props.url} target="_blank">
								<div className={styles.overflowEllipsis}>{props.name}</div>
							</a>
						</div>
					) : (
						<div className={styles.title}>
							<div className={styles.overflowEllipsis}>{props.name}</div>
						</div>
					)}
				</div>
				{props.allowHover ? (
					<div className={expanded ? styles.hover : styles.hideUntilHover}>
						<EditTwoTone onClick={props.onEditClick} className={styles.margin} />
						<Popconfirm
							title={
								<div className={styles.popconfirmTitleContainer}>
									<div className={styles.overflowEllipsis}>Are you sure to delete {props.name}</div>
									?
								</div>
							}
							onVisibleChange={() => {
								setExpanded(!expanded);
							}}
							onConfirm={() => {
								props.onDeleteClick ? props.onDeleteClick() : null;
							}}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone className={styles.margin} />
						</Popconfirm>
					</div>
				) : null}
			</div>
		</Tooltip>
	);
};
export { SkillTile };
